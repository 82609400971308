$(document).ready(function(){

    var table = $('.datatable').DataTable({
      dom: "<f<t>lip>",
      stateSave: true,
      language: {
        "emptyTable": "Não foi encontrado nenhum registo",
        "loadingRecords": "A carregar...",
        "processing": "A processar...",
        "lengthMenu": "Mostrar _MENU_ registos",
        "zeroRecords": "Não foram encontrados resultados",
        "search": "",
        "searchPlaceholder": "Pesquisar...",
        "paginate": {
            "first": "Primeiro",
            "previous": "Anterior",
            "next": "Seguinte",
            "last": "Último"
        },
        "aria": {
            "sortAscending": ": Ordenar colunas de forma ascendente",
            "sortDescending": ": Ordenar colunas de forma descendente"
        },
        "autoFill": {
            "cancel": "cancelar",
            "fill": "preencher",
            "fillHorizontal": "preencher células na horizontal",
            "fillVertical": "preencher células na vertical"
        },
        "buttons": {
            "collection": "Coleção",
            "colvis": "Visibilidade de colunas",
            "colvisRestore": "Restaurar visibilidade",
            "copy": "Copiar",
            "copySuccess": {
                "1": "Uma linha copiada para a área de transferência",
                "_": "%ds linhas copiadas para a área de transferência"
            },
            "copyTitle": "Copiar para a área de transferência",
            "csv": "CSV",
            "excel": "Excel",
            "pageLength": {
                "-1": "Mostrar todas as linhas",
                "_": "Mostrar %d linhas"
            },
            "pdf": "PDF",
            "print": "Imprimir",
            "copyKeys": "Pressionar CTRL ou u2318 + C para copiar a informação para a área de transferência. Para cancelar, clique nesta mensagem ou pressione ESC.",
            "createState": "Criar",
            "removeAllStates": "Remover Todos",
            "removeState": "Remover",
            "renameState": "Renomear",
            "savedStates": "Gravados",
            "stateRestore": "Estado %d",
            "updateState": "Atualizar"
        },
        "decimal": "",
        "infoFiltered": "(filtrado num total de _MAX_ registos)",
        "infoThousands": ".",
        "searchBuilder": {
            "add": "Adicionar condição",
            "button": {
                "0": "Construtor de pesquisa",
                "_": "Construtor de pesquisa (%d)"
            },
            "clearAll": "Limpar tudo",
            "condition": "Condição",
            "conditions": {
                "date": {
                    "after": "Depois",
                    "before": "Antes",
                    "between": "Entre",
                    "empty": "Vazio",
                    "equals": "Igual",
                    "not": "Diferente",
                    "notBetween": "Não está entre",
                    "notEmpty": "Não está vazio"
                },
                "number": {
                    "between": "Entre",
                    "empty": "Vazio",
                    "equals": "Igual",
                    "gt": "Maior que",
                    "gte": "Maior ou igual a",
                    "lt": "Menor que",
                    "lte": "Menor ou igual a",
                    "not": "Diferente",
                    "notBetween": "Não está entre",
                    "notEmpty": "Não está vazio"
                },
                "string": {
                    "contains": "Contém",
                    "empty": "Vazio",
                    "endsWith": "Termina em",
                    "equals": "Igual",
                    "not": "Diferente",
                    "notEmpty": "Não está vazio",
                    "startsWith": "Começa em",
                    "notContains": "Não contém",
                    "notStarts": "Não começa com",
                    "notEnds": "Não termina com"
                },
                "array": {
                    "equals": "Igual",
                    "empty": "Vazio",
                    "contains": "Contém",
                    "not": "Diferente",
                    "notEmpty": "Não está vazio",
                    "without": "Sem"
                }
            },
            "data": "Dados",
            "deleteTitle": "Excluir condição de filtragem",
            "logicAnd": "E",
            "logicOr": "Ou",
            "title": {
                "0": "Construtor de pesquisa",
                "_": "Construtor de pesquisa (%d)"
            },
            "value": "Valor",
            "leftTitle": "Excluir critério",
            "rightTitle": "Incluir critério"
        },
        "searchPanes": {
            "clearMessage": "Limpar tudo",
            "collapse": {
                "0": "Painéis de pesquisa",
                "_": "Painéis de pesquisa (%d)"
            },
            "count": "{total}",
            "countFiltered": "{shown} ({total})",
            "emptyPanes": "Sem painéis de pesquisa",
            "loadMessage": "A carregar painéis de pesquisa",
            "title": "Filtros ativos",
            "showMessage": "Mostrar todos",
            "collapseMessage": "Ocultar Todos"
        },
        "select": {
            "cells": {
                "1": "1 célula seleccionada",
                "_": "%d células seleccionadas"
            },
            "columns": {
                "1": "1 coluna seleccionada",
                "_": "%d colunas seleccionadas"
            },
            "rows": {
                "1": "%d linha seleccionada",
                "_": "%d linhas seleccionadas"
            }
        },
        "thousands": ".",
        "editor": {
            "close": "Fechar",
            "create": {
                "button": "Novo",
                "title": "Criar novo registro",
                "submit": "Criar"
            },
            "edit": {
                "button": "Editar",
                "title": "Editar registro",
                "submit": "Atualizar"
            },
            "remove": {
                "button": "Remover",
                "title": "Remover",
                "submit": "Remover",
                "confirm": {
                    "_": "Tem certeza que quer apagar %d entradas?",
                    "1": "Tem certeza que quer apagar esta entrada?"
                }
            },
            "multi": {
                "title": "Multiplos valores",
                "restore": "Desfazer alterações",
                "info": "Os itens selecionados contêm valores diferentes para esta entrada. Para editar e definir todos os itens nesta entrada com o mesmo valor, clique ou toque aqui, caso contrário, eles manterão os seus valores individuais.",
                "noMulti": "Este campo pode ser editado individualmente mas não pode ser editado em grupo"
            },
            "error": {
                "system": "Ocorreu um erro no sistema"
            }
        },
        "info": "_START_ de _TOTAL_",
        "infoEmpty": "",
        "datetime": {
            "previous": "anterior",
            "next": "próximo",
            "hours": "horas",
            "minutes": "minutos",
            "seconds": "segundos",
            "unknown": "desconhecido",
            "amPm": [
                "am",
                "pm"
            ],
            "weekdays": [
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sáb",
                "Dom"
            ],
            "months": [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ]
        }
      },
      columnDefs: [
        {
          targets: 'no-sort',
          orderable: false,
        }
      ],
    });
    // fix bug on search and reload
    if(table && table['context'] && table['context'][0] && table['context'][0]['oPreviousSearch']['sSearch']){
      $('#search').val(table['context'][0]['oPreviousSearch']['sSearch']);
    }
    $('#search').keyup(function() {
      table.search($(this).val()).draw();
    });

    $('.table-toggler').on('click', function() {
      var lineId = $(this).attr('line-id');
      var next = $(this).parent().parent().next('tr');

      if (next && next.attr('id')) {
        $('tr#tr_' + lineId + ' div').removeClass('table-open');
        setTimeout(() => {
          next.remove();
        }, 300); // CSS animation time
        $(this).text('Mostrar');
      } else {
        $(this).parent().parent().after('<tr id="tr_' + lineId + '">' + $('tr#' + lineId).html() + '</tr>');
        setTimeout(() => {
          $('tr#tr_' + lineId + ' div').addClass('table-open');
        }, 10);
        $(this).text('Ocultar');
      }
    });

    function removeAccents ( data ) {
      if ( data.normalize ) {
        // Use I18n API if avaiable to split characters and accents, then remove
        // the accents wholesale. Note that we use the original data as well as
        // the new to allow for searching of either form.
        return data +' '+ data
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
      }

      return data;
    }

    var searchType = jQuery.fn.DataTable.ext.type.search;
    searchType.string = function ( data ) {
      return ! data ?
        '' :
        typeof data === 'string' ?
          removeAccents( data ) :
          data;
    };

    searchType.html = function ( data ) {
      return ! data ?
        '' :
        typeof data === 'string' ?
          removeAccents( data.replace( /<.*?>/g, '' ) ) :
          data;
    };
});